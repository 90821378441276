@import './base.css';

.selected-type {
  background-color: #ebf5ff;
  border-color: #3b82f6;
  color: #3b82f6;
}

#dropzone {
  position: relative;
}
#dropzone input {
  position: absolute;
  opacity: 0;
  right: 0;
  bottom: 0;
}
